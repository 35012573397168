// główny layout

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';
import { ChevronUp } from 'react-bootstrap-icons';
import classnames from 'classnames';

import { useRWD } from 'hooks';
import { useSelector } from 'store';
import { Footer, HeaderMain, HeaderBottomBar, HeaderTopBar, MenuPopup } from './components';

import styles from 'theme/components/layouts/MainLayout/Main.module.scss';

const HEADER_HEIGHT = 173;

const Main = () => {
  const location = useLocation();
  const { profile } = useSelector((state) => state.auth);
  const { isMobileMenu } = useSelector((state) => state.ui);
  const { isMobile } = useRWD();

  // czy jest widoczny przycisk powrotu na górę strony
  const [isBackToTopButton, setIsBackToTopButton] = useState(false);

  // czy jest aktywowana opcja prostego menu
  const [isSimpleHeader, setIsSimpleHeader] = useState(false);

  // funkcja skrolująca stronę do góry
  const scrollToTop = useCallback(
    () => document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    []
  );

  // przeskrolowanie strony do góry przy przejściu między stronami
  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      document.documentElement.scrollTop > 500
        ? setIsBackToTopButton(true)
        : setIsBackToTopButton(false);
    });
  }, []);

  // dodanie wventu na ukrycie headera
  useEffect(() => {
    handleSimpleHeader();

    if (!isMobile) {
      document.addEventListener('scroll', handleSimpleHeader);
    }
  }, []);

  const handleSimpleHeader = useCallback(() => {
    if (window.scrollY > HEADER_HEIGHT) {
      setIsSimpleHeader(true);
    } else {
      setIsSimpleHeader(false);
    }
  }, [isSimpleHeader]);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        { [styles.simpleHeader]: isSimpleHeader },
        'StylePath-Components-Layouts-MainLayout-Main'
      )}>
      {isMobile ? (
        <header>
          <HeaderMain simpleHeader={isSimpleHeader} />
        </header>
      ) : (
        (!isMobile || location.pathname.split('/').filter((item) => item).length < 2) && (
          <header>
            <HeaderTopBar simpleHeader={isSimpleHeader} />
            <HeaderMain />
            <HeaderBottomBar />
          </header>
        )
      )}
      <div
        // Ta klasa jest używana w innych komponentach - nie zmieniać!
        className={classnames('scrollableContent', styles.content)}>
        <main>
          <Outlet />
        </main>
        <footer>
          <Footer />
        </footer>
      </div>

      {isMobile && isMobileMenu && profile?.role !== 'ROLE_OPEN_PROFILE' && <MenuPopup />}

      {isBackToTopButton && (
        <button onClick={scrollToTop} className={styles.backToTop}>
          <ChevronUp />
        </button>
      )}
    </div>
  );
};

export default Main;
