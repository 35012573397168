// widok pozycji w wersji rozszerzonej

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { format } from 'date-fns';

import { IColumn } from 'components/controls/Table';
import { IDocumentListItemVmp, IDocumentsInformationVmpResponse } from 'api/types';
import { DocumentStatus } from './components';

import { CheckIcon } from 'assets/icons';

import styles from 'theme/pages/Documents/Documents.module.scss';

export const getExtendedColumns = ({
  documentsInformation
}: {
  documentsInformation?: IDocumentsInformationVmpResponse;
}): IColumn<IDocumentListItemVmp>[] => [
  {
    title: <Trans>Data utworzenia</Trans>,
    dataIndex: 'create_datetime',
    align: 'center',
    sortBy: 'INSERT',
    renderCell: (item) => (
      <span className={styles.cell}>{format(new Date(item.create_datetime), 'dd.MM.yyyy')}</span>
    )
  },
  {
    title: <Trans>Numer dokumentu</Trans>,
    dataIndex: 'symbol',
    align: 'center',
    renderCell: (item) => (
      <span className={classnames(styles.cell, styles.documentNumber)}>{item.symbol}</span>
    )
  },
  {
    title: <Trans>Dokument źródłowy</Trans>,
    dataIndex: 'source_document',
    align: 'center',
    renderCell: (item) => (
      <span
        className={classnames(styles.documentsCell, styles.cell, {
          [styles.hasDocument]: !!item?.source_document
        })}
        dangerouslySetInnerHTML={{ __html: item?.source_document || '' }}></span>
    )
  },
  {
    title: <Trans>Zbiorcza</Trans>,
    dataIndex: 'associated_with',
    align: 'center',
    renderCell: (item) => (
      <span className={styles.cell}>{item.associated_with && <CheckIcon />}</span>
    )
  },
  {
    title: <Trans>Wartość brutto</Trans>,
    dataIndex: 'value_gross',
    align: 'center',
    sortBy: 'GROSS',
    renderCell: (item) => (
      <span className={styles.cell}>
        {item.value_gross_formatted} {item.currency}
      </span>
    )
  },
  {
    title: <Trans>Termin płatności</Trans>,
    dataIndex: 'payment_datetime',
    align: 'center',
    sortBy: 'PAYMENT',
    renderCell: (item) => (
      <span className={styles.cell}>
        {item.payment_datetime && format(new Date(item.payment_datetime), 'dd.MM.yyyy')}
      </span>
    )
  },
  {
    title: <Trans>Dni po terminie</Trans>,
    dataIndex: 'days_after_payment_deadline',
    align: 'center',
    sortBy: 'DEADLINE',
    renderCell: (item) => (
      <span className={classnames(styles.cell, styles.daysExpired)}>
        {item.days_after_payment_deadline}
      </span>
    )
  },
  {
    title: <Trans>Status</Trans>,
    dataIndex: 'is_paid',
    align: 'center',
    sortBy: 'PAID',
    renderCell: (item) => <DocumentStatus item={item} documentsInformation={documentsInformation} />
  }
];
