// Hook odpowiedzialny za pobranie profilu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IAgreementListItem } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  id: number;
  change_password: boolean;
  consents_required: boolean;
  first_name: string;
  last_name: string;
  email: string;
  overloged: false;
  role: 'ROLE_USER_MAIN' | 'ROLE_USER' | 'ROLE_TRADER' | 'ROLE_ADMIN' | 'ROLE_OPEN_PROFILE';
  sale_supervisor: {
    name: string;
    phone: string;
    email: string;
  };
  agreements: IAgreementListItem[];
  customer: {
    first_name: string;
    last_name: string;
    name: string;
    address: {
      street: string;
      building: string;
      apartment: string;
      postal_code: string;
      city: string;
      state: string;
      country: string;
      country_code: string;
    };
    nip: string;
    email: string;
    phone: string;
  };
  primary_shipping_address: {
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address: {
      name: string;
      street: string;
      building: string;
      apartment: string;
      postal_code: string;
      city: string;
      state: string;
      country: string;
      country_code: string;
    };
  };
  additional_shipping_address: {
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address: {
      name: string;
      street: string;
      building: string;
      apartment: string;
      postal_code: string;
      city: string;
      state: string;
      country: string;
      country_code: string;
    };
  };
  contact_data: {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    website: string;
  };
}

const getUserProfileVmp = (): Promise<IResponse> => axios.get('/users/profile/vmp');

export const useGetUserProfileVmp = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>('profile', getUserProfileVmp, options);
