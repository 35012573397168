// Hook odpowiedzialny za zmianę danych w profilu

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
export interface IRequest {
  customer: {
    nip: string;
    name: string;
    first_name: string;
    last_name: string;
    address: {
      street: string;
      postal_code: string;
      city: string;
      building: string;
      apartment: string;
      country: string;
    };
  };
  delivery_address: {
    name: string;
    first_name: string;
    last_name: string;
    address: {
      street: string;
      postal_code: string;
      city: string;
      building: string;
      apartment: string;
      country: string;
    };
  };
  contact: {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  consent?: boolean;
  captcha?: string;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postUserProfile = (data: IRequest): Promise<IResponse> => axios.post('/users/profile', data);

export const usePostUserProfile = (options?: UseMutationOptions<IResponse, IError, IRequest>) =>
  useMutation((data: IRequest) => postUserProfile(data), options);
