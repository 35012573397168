// strona główna

import React from 'react';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import '../../theme/pages/Home/Home.scss';

const Home = () => {
  return (
    <div className="home-page">
      <HtmlBlock sectionId="HOME_DESKTOP" />
    </div>
  );
};

export default Home;
