// baner ze sliderem, odliczaniem i zajawkami aktualności

import React, { useMemo } from 'react';
import {
  CarouselProvider,
  Dot,
  Slide,
  Slider as PureSlider,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import qs from 'query-string';
import classnames from 'classnames';

import { useGetHomeSlider } from 'api';
import { IHomeSliderListItem } from 'api/types';
import { Link } from 'components/controls';
import { SliderArrow } from 'assets/icons';
import { useRWD } from 'hooks';

import styles from 'theme/pages/Home/components/Slider/Slider.module.scss';

export const Slider = () => {
  const { isMobile } = useRWD();

  // zmapowanie zmiennej isMobile na kody pochodzące z response layout
  const device = isMobile ? 'Mobile' : 'Desktop';

  // pobranie danych slidera
  const { data: homeSliderData } = useGetHomeSlider(isMobile);

  // lista slajdów po filtrze device
  const slides = useMemo(
    () => (homeSliderData?.items || []).filter((item) => item.layouts?.includes(device)),
    [homeSliderData, device]
  );

  const renderSliderContent = (slideData: IHomeSliderListItem, index: number) => {
    if (slideData.layouts?.includes(device)) {
      return (
        <Slide className={styles.slide} key={slideData.id} index={index}>
          {slideData.url ? (
            <a
              href={slideData.url}
              target={slideData.new_tab ? '_blank' : '_self'}
              className={styles.slideLink}
              rel="noreferrer">
              <img src={slideData.image} />
            </a>
          ) : (
            <Link
              to={`/products?${qs.stringify(
                {
                  category_id: slideData.category_id,
                  search_keyword: slideData.search_keyword,
                  sort_method: slideData.sort_method
                },
                { skipNull: true }
              )}`}
              target={slideData.new_tab ? '_blank' : '_self'}
              className={styles.slideLink}>
              <img src={slideData.image} />
            </Link>
          )}
        </Slide>
      );
    }

    return null;
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Home-components-Slider')}>
      {homeSliderData && (
        <div className={styles.slider}>
          <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={1}
            totalSlides={slides.length || 0}
            visibleSlides={1}
            isPlaying>
            <PureSlider>
              {(slides || []).map((slideData, index) => renderSliderContent(slideData, index))}
            </PureSlider>

            <div className={styles.sliderLinks}>
              {slides.map((slideData, index) => (
                <Dot slide={index} key={slideData.id}>
                  <div className={styles.dot}></div>
                </Dot>
              ))}
            </div>
            <div className={styles.arrows}>
              <ButtonBack>
                <SliderArrow />
              </ButtonBack>
              <ButtonNext>
                <SliderArrow />
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div>
      )}
    </div>
  );
};

export default Slider;
