// widok pozycji w wersji default

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { format } from 'date-fns';

import { IColumn } from 'components/controls/Table';
import { IDocumentListItemVmp, IDocumentsInformationVmpResponse } from 'api/types';
import { DocumentStatus } from './components';

import { Download } from 'assets/icons';

import styles from 'theme/pages/Documents/Documents.module.scss';

export const getSimpleColumns = ({
  documentsInformation
}: {
  documentsInformation?: IDocumentsInformationVmpResponse;
}): IColumn<IDocumentListItemVmp>[] => [
  {
    title: <Trans>Data utworzenia</Trans>,
    dataIndex: 'create_datetime',
    align: 'center',
    sortBy: 'INSERT',
    renderCell: (item) => (
      <span className={styles.cell}>{format(new Date(item.create_datetime), 'dd.MM.yyyy')}</span>
    )
  },
  {
    title: <Trans>Numer dokumentu</Trans>,
    dataIndex: 'symbol',
    align: 'center',
    renderCell: (item) => (
      <span className={classnames(styles.cell, styles.documentNumber)}>
        {item.symbol} {item.has_file && <Download />}
      </span>
    )
  },
  {
    title: <Trans>Numer zamówienia</Trans>,
    align: 'center',
    renderCell: (item) => <span>{item?.order_id}</span>
  },
  {
    title: <Trans>Wartość brutto</Trans>,
    dataIndex: 'value_gross',
    align: 'center',
    sortBy: 'GROSS',
    renderCell: (item) => (
      <span className={styles.cell}>
        {item.value_gross_formatted.replace('.', ',')} {item.currency}
      </span>
    )
  },
  {
    title: <Trans>Status</Trans>,
    dataIndex: 'is_paid',
    align: 'center',
    sortBy: 'PAID',
    renderCell: (item) => <DocumentStatus item={item} documentsInformation={documentsInformation} />
  }
];
