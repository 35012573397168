import React, { FC, PropsWithChildren } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import map from 'lodash/map';

import { useGetUserProfileVmp, usePutUserProfileVmp, usePostUserProfile } from 'api';
import { useNotifications, useRWD } from 'hooks';
import { Button, Input, FormElement, Checkbox } from 'components/controls';
import { EmailIcon, PhoneIcon } from 'assets/icons';

import styles from 'theme/components/containers/UserForm/UserForm.module.scss';

interface ITitleProps extends PropsWithChildren {
  secondary?: boolean;
}

// typ danych wejściowych
interface IProps {
  isRegisterForm?: boolean;
}

const UserForm: FC<IProps> = ({ isRegisterForm }) => {
  const { t } = useTranslation();
  const { showSuccessMessage } = useNotifications();
  const { isMobile } = useRWD();
  const { pathname } = useLocation();

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = pathname.slice(0, getPosition(pathname, '/', 2)).replaceAll('/', '');
  console.log(urlPrefix);

  const { data: profile, refetch: refetchUserProfileData } = useGetUserProfileVmp();

  // aktualizacja danych profilu
  const { mutate: changeProfile, isLoading: isUpdatingProfile } = usePutUserProfileVmp(
    profile?.id || 0,
    {
      onSuccess: () => {
        refetchUserProfileData();
      },
      onError: (errors) => {
        map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
      }
    }
  );

  // aktualizacja danych profilu
  const { mutate: createProfile, isLoading: isCreatingProfile } = usePostUserProfile({
    onSuccess: (data) => {
      showSuccessMessage(data.message);
    }
  });

  // obsługa danych formularza
  const { values, errors, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      nip: profile?.customer.nip || '',
      name: profile?.customer.name || '',
      first_name: profile?.customer.first_name || '',
      last_name: profile?.customer.last_name || '',
      street: profile?.customer.address?.street || '',
      building: profile?.customer.address?.building || '',
      apartment: profile?.customer.address?.apartment || '',
      postal_code: profile?.customer.address?.postal_code || '',
      city: profile?.customer.address?.city || '',
      country: profile?.customer.address?.country || '',
      primary_shipping_address: {
        name: profile?.primary_shipping_address?.name || '',
        first_name: profile?.primary_shipping_address?.first_name || '',
        last_name: profile?.primary_shipping_address?.last_name || '',
        address: {
          street: profile?.primary_shipping_address?.address.street || '',
          building: profile?.primary_shipping_address?.address.building || '',
          apartment: profile?.primary_shipping_address?.address.apartment || '',
          postal_code: profile?.primary_shipping_address?.address.postal_code || '',
          city: profile?.primary_shipping_address?.address.city || '',
          country: profile?.primary_shipping_address?.address.country || ''
        }
      },
      contact_person_first_name: profile?.contact_data?.firstname || '',
      contact_person_last_name: profile?.contact_data?.lastname || '',
      phone: profile?.contact_data?.phone || '',
      email: profile?.contact_data?.email || '',
      ...(isRegisterForm && {
        consent: false,
        captcha: ''
      })
    },
    onSubmit: (values) => {
      if (isRegisterForm) {
        // createProfile(values);

        return;
      }

      const {
        primary_shipping_address,
        contact_person_first_name,
        contact_person_last_name,
        phone
      } = values;

      changeProfile({
        primary_shipping_address,
        contact_person_first_name,
        contact_person_last_name,
        phone
      });
    },
    validateOnChange: false,
    enableReinitialize: true
  });

  const Title: FC<ITitleProps> = ({ children, secondary }) => (
    <div className={classnames(styles.title, { [styles.secondary]: secondary })}>{children}</div>
  );

  const renderConsent = () =>
    isRegisterForm &&
    typeof values.consent === 'boolean' && (
      <FormElement>
        <label className={styles.consent}>
          <Checkbox
            checked={values.consent}
            onClick={() => setFieldValue('consent', !values.consent)}
          />
          <span className={styles.label}>
            <Trans>Oświadczam, że znam i akceptuję postanowienia Regulaminu.</Trans>
          </span>
        </label>
        <span className={styles.error}>{errors.consent}</span>
      </FormElement>
    );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.accountWrapper}>
        <div className={styles.section}>
          <div className={styles.invoice}>
            <Title>
              <Trans>Dane do faktury</Trans>
            </Title>
            {!isRegisterForm && (
              <div className={styles.contact}>
                <a href="mailto:hurt@vmp.com.pl">
                  <Trans>Skontaktuj się, aby zmnienić dane</Trans>
                </a>
              </div>
            )}
          </div>
          <span className={styles.label}>
            <Trans>NIP/VAT EU</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.nip}
              onChange={(value) => setFieldValue('nip', value)}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors?.nip })}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Nazwa firmy</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.name}
              onChange={(value) => setFieldValue('name', value)}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors?.name })}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Imię</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.first_name}
              onChange={(value) => setFieldValue('first_name', value)}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors?.first_name })}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Nazwisko</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.last_name}
              onChange={(value) => setFieldValue('last_name', value)}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors?.last_name })}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Ulica</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.street}
              onChange={(value) => setFieldValue('street', value)}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors?.street })}
            />
          </FormElement>

          <div className={styles.halfWidth}>
            <div>
              <span className={styles.label}>
                <Trans>Nr budynku</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.building}
                  onChange={(value) => setFieldValue('building', value)}
                  disabled={!isRegisterForm}
                  {...(isRegisterForm && { error: errors?.building })}
                />
              </FormElement>
            </div>
            <div>
              <span className={styles.label}>
                <Trans>Nr lokalu</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.apartment}
                  onChange={(value) => setFieldValue('apartment', value)}
                  disabled={!isRegisterForm}
                  {...(isRegisterForm && { error: errors?.apartment })}
                />
              </FormElement>
            </div>
          </div>
          <div className={styles.halfWidth}>
            <div>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.postal_code}
                  onChange={(value) => setFieldValue('postal_code', value)}
                  disabled={!isRegisterForm}
                  {...(isRegisterForm && { error: errors?.postal_code })}
                />
              </FormElement>
            </div>
            <div>
              <span className={styles.label}>
                <Trans>Miasto</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.city}
                  onChange={(value) => setFieldValue('city', value)}
                  disabled={!isRegisterForm}
                  {...(isRegisterForm && { error: errors?.city })}
                />
              </FormElement>
            </div>
          </div>
          <span className={styles.label}>
            <Trans>Kraj</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              onChange={(value) => setFieldValue('country', value)}
              value={values.country}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors?.country })}
            />
          </FormElement>
        </div>
        <div className={classnames(styles.section, styles.sectionMiddle)}>
          <Title>
            <Trans>Dane do wysyłki</Trans>
          </Title>
          <span className={styles.label}>
            <Trans>Nazwa firmy</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.primary_shipping_address.name}
              onChange={(value) => setFieldValue('primary_shipping_address.name', value)}
              error={errors.primary_shipping_address?.name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Imię</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.primary_shipping_address.first_name}
              onChange={(value) => setFieldValue('primary_shipping_address.first_name', value)}
              error={errors.primary_shipping_address?.first_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Nazwisko</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.primary_shipping_address.last_name}
              onChange={(value) => setFieldValue('primary_shipping_address.last_name', value)}
              error={errors.primary_shipping_address?.last_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Ulica</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.primary_shipping_address.address.street}
              onChange={(value) => setFieldValue('primary_shipping_address.address.street', value)}
              error={errors.primary_shipping_address?.address?.street}
            />
          </FormElement>
          <div className={styles.halfWidth}>
            <FormElement>
              <span className={styles.label}>
                <Trans>Nr budynku</Trans>
              </span>
              <Input
                type="text"
                value={values.primary_shipping_address.address.building}
                onChange={(value) =>
                  setFieldValue('primary_shipping_address.address.building', value)
                }
                error={errors.primary_shipping_address?.address?.building}
              />
            </FormElement>
            <FormElement>
              <span className={styles.label}>
                <Trans>Nr lokalu</Trans>
              </span>
              <Input
                type="text"
                value={values.primary_shipping_address.address.apartment}
                onChange={(value) =>
                  setFieldValue('primary_shipping_address.address.apartment', value)
                }
                error={errors.primary_shipping_address?.address?.apartment}
              />
            </FormElement>
          </div>

          <div className={styles.halfWidth}>
            <FormElement>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>
              </span>
              <Input
                type="text"
                value={values.primary_shipping_address.address.postal_code}
                onChange={(value) =>
                  setFieldValue('primary_shipping_address.address.postal_code', value)
                }
                error={errors.primary_shipping_address?.address?.postal_code}
              />
            </FormElement>
            <FormElement>
              <span className={styles.label}>
                <Trans>Miasto</Trans>
              </span>
              <Input
                type="text"
                value={values.primary_shipping_address.address.city}
                onChange={(value) => setFieldValue('primary_shipping_address.address.city', value)}
                error={errors.primary_shipping_address?.address?.city}
              />
            </FormElement>
          </div>
          <span className={styles.label}>
            <Trans>Kraj</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.primary_shipping_address.address.country}
              onChange={(value) => setFieldValue('primary_shipping_address.address.country', value)}
              disabled={!isRegisterForm}
              {...(isRegisterForm && { error: errors.primary_shipping_address?.address?.country })}
            />
          </FormElement>
          {!isMobile && renderConsent()}
        </div>
        <div className={styles.section}>
          <Title>
            <Trans>Dane kontaktowe</Trans>
          </Title>
          <span className={styles.label}>
            <Trans>Imię</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.contact_person_first_name}
              onChange={(value) => setFieldValue('contact_person_first_name', value)}
              error={errors.contact_person_first_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Nazwisko</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.contact_person_last_name}
              onChange={(value) => setFieldValue('contact_person_last_name', value)}
              error={errors.contact_person_last_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Telefon</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value)}
              error={errors.phone}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Email</Trans>
          </span>
          <Input
            placeholder={t('Email')}
            type="text"
            onChange={(value) => setFieldValue('email', value)}
            value={values.email}
            disabled={!isRegisterForm}
            {...(isRegisterForm && { error: errors.email })}
          />
          {isRegisterForm && process.env.REACT_APP_RECAPTHA_SITE_KEY ? (
            <div className={styles.captcha}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTHA_SITE_KEY}
                onChange={(value) => setFieldValue('captcha', value)}
                hl={urlPrefix}
              />
              <span className={styles.error}>{errors.captcha}</span>
            </div>
          ) : (
            <div className={styles.info}>
              <b>
                <Trans>Skontaktuj się</Trans>
              </b>
              ,{' '}
              <span>
                <Trans>aby zmienić dane</Trans>
              </span>
              <div>
                <div>
                  <a href="mailto:hurt@vmp.com.pl">
                    <EmailIcon />
                    hurt@vmp.com.pl
                  </a>
                </div>
                <div>
                  <PhoneIcon />
                  +48 505 44 94 95
                </div>
              </div>
            </div>
          )}
          {isMobile && renderConsent()}
          <Button htmlType="submit" loading={isUpdatingProfile || isCreatingProfile}>
            <Trans>{isRegisterForm ? 'Zarejestruj się' : 'Zapisz zmiany'}</Trans>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
