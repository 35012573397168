/* eslint-disable @typescript-eslint/no-unused-vars */
// url resolver

import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

import { useGetLayoutUrlResolver } from 'api';

import { Products, Product, NoMatch, CmsPage } from 'pages';

interface IParams {
  category_id?: string;
  product_id?: string;
}

const UrlResolver = () => {
  // ścieżka z url
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = pathname.slice(0, getPosition(pathname, '/', 2));

  // url key
  const urlKey = pathname.replace(urlPrefix, '').replace('/', '');

  const {
    data: urlResolverData,
    refetch: getUrlResolver,
    status
  } = useGetLayoutUrlResolver(
    { urlKey },
    {
      enabled: false,
      onSuccess: ({ parameters }) => {
        const { category_id, product_id, ...rest } = qs.parse(parameters);

        const urlParams = { ...rest, ...qs.parse(search) };

        navigate(pathname + `?${qs.stringify(urlParams)}`, { replace: true });
      }
    }
  );

  useEffect(() => {
    getUrlResolver();
  }, [urlKey]);

  const renderContent = () => {
    const { category_id, product_id, ...rest } = qs.parse(
      urlResolverData?.parameters || ''
    ) as IParams;
    if (urlResolverData?.page_type === 'PRODUCTS_LIST') {
      return <Products categoryId={category_id} />;
    }

    if (urlResolverData?.page_type === 'PRODUCT_DETAILS' && product_id) {
      return <Product id={product_id} />;
    }

    if (urlResolverData?.page_type === 'SUBPAGE') {
      return <CmsPage sectionId="SUBPAGE" articleId={urlResolverData.parameters} />;
    }

    if (status === 'error') {
      return <NoMatch />;
    }

    return null;
  };

  return renderContent();
};

export default UrlResolver;
